// extracted by mini-css-extract-plugin
export var buttonBannerAppMobile = "index-module--buttonBannerAppMobile--208d0";
export var customBanner = "index-module--customBanner--76728";
export var customBannerAppMargin = "index-module--customBannerAppMargin--6ac07";
export var customCupomPosition = "index-module--customCupomPosition--dc96a";
export var customPhonePosition = "index-module--customPhonePosition--d5b11";
export var diaCircle = "index-module--diaCircle--3152b";
export var diss = "index-module--diss--de2eb";
export var iconsImage = "index-module--iconsImage--5aac7";
export var imgFav1 = "index-module--imgFav1--a0001";
export var imgFav2 = "index-module--imgFav2--e3403";
export var imgFavMobile = "index-module--imgFavMobile--1aa0c";
export var offersImageHome = "index-module--offersImageHome--5870a";
export var phoneBannerAppMobile = "index-module--phoneBannerAppMobile--de902";
export var swiperHome = "index-module--swiperHome--a0e42";
export var swiperInsta = "index-module--swiperInsta--cb736";
export var swiperOfertas = "index-module--swiperOfertas--2c597";
export var swiperTemNoDia = "index-module--swiperTemNoDia--4de56";
export var temNoDiaItemText = "index-module--temNoDiaItemText--d9dee";
export var temNoDiaSize = "index-module--temNoDiaSize--daedb";